<template>
    <div class="record-container">
        <div class="header-section">
            <p class="header-title">船舶通行记录列表（合计{{ total }}条数据）</p>
        </div>
        <div class="search-section">
            <div class="search-item">
                <span class="search-title">船舶名称：</span>
                <Input v-model="searchForm.shipName" placeholder="请输入船舶名称" style="width: 150px"></Input>
            </div>
            <div class="search-item">
                <span class="search-title">船舶编码：</span>
                <Input v-model="searchForm.shipId" placeholder="请输入船舶ID" style="width: 150px"></Input>
            </div>
            <div class="search-item">
                <span class="search-title">时间段：</span>
                <Date-picker format="yyyy-MM-dd HH:mm:ss" value="yyyy-MM-dd HH:mm:ss" v-model="searchForm.searchDateArr" type="datetimerange" placeholder="选择日期" style="width: 250px"></Date-picker>
            </div>
            <Button @click="searchHandler" style="margin-left: 20px" type="success" class="btn">搜索</Button>
            <Button @click="resetHandler" style="margin-left: 20px" type="default" class="btn">重置</Button>
        </div>
        <div class="record-content">
            <div class="record-list">
                <div class="record-item" :key="index" v-for="(item, index) in videoList">
                    <RecordICell @recordCellTap="recordCellTapHandler" :index="index" :recordObj="item"></RecordICell>
                </div>
            </div>
        </div>
        <div class="page-section">
            <Page @on-change="pageChange" @on-page-size-change="pageSizeChange" :total="total" size="small"
                style="margin-top: 20px" show-total show-elevator show-sizer></Page>
        </div>
        <div v-if="videoDetailShow" class="mask">
            <div class="detail-content">
                <div class="detail-header">
                    <p class="detail-header-title">通行记录</p>
                </div>
                <div class="video-section">
                    <template v-if="currentVideoDetailObj.isVideo">
                        <video ref="videoPlayer" class="video-js"></video>
                    </template>
                    <template v-else>
                        <img class="video-img" :src="currentVideoDetailObj.url" alt="" />
                    </template>
                </div>
                <div class="img-list">
                    <div @click="videoDetailHandler(index)" v-for="(item, index) in videoDetailList" :key="index"
                        class="img-list-item">
                        <img class="video-img" :src="item.smallUrl" alt="">
                        <img v-if="item.isVideo" class="play-img" src="../../assets/video_play.png" alt="">
                    </div>
                </div>
                <div class="btn-section">
                    <Button @click="closeModalHandler" style="margin-left: 20px" type="primary" class="btn">关闭</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RecordICell from '../../components/record/record-cell.vue';
import MenuAddress from '../../components/menu/menu-address.vue';
import { getShopVideoInfoList, getVideoDetailInfo } from '@/utils/api';
import { customPrint } from '@/utils/util';
import { getDateByDatePicker } from '@/utils/util';

export default {
    components: {
        RecordICell,
        MenuAddress
    },
    data() {
        return {
            searchForm: {
                shipName: '',
                shipId: '',
                searchDateArr: []
            },
            videoList: [
            ],
            addressList: [
                {
                    title: '小西门',
                    addressId: '2323'
                }, {
                    title: '渡口',
                    addressId: '2323'
                }, {
                    title: '大西门',
                    addressId: '2323'
                }, {
                    title: '鹦鹉洲大桥',
                    addressId: '2323'
                }, {
                    title: '白沙洲大桥',
                    addressId: '2323'
                }, {
                    title: '小龙门',
                    addressId: '2323'
                }
            ],
            selectAddressIndex: 0,
            total: 0,
            pageSize: 10,
            pageNum: 1,
            videoDetailShow: false,
            videoDetailList: [],
            currentVideoDetailObj: {},
            player: {},
            playerOptions: {
                autoplay: true, // 如果true,浏览器准备好时开始播放。
                muted: true, // 默认情况下将会消除任何音频。
                loop: true, // 导致视频一结束就重新开始。
                preload: 'auto', // auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',  //汉化
                fluid: true, // 当true时，播放器将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: 'application/x-mpegURL',
                    src: 'http://hls01open.ys7.com/openlive/b683d702d339431cafb483e9c5e67c95.m3u8'  //视频播放地址
                }],
                // width: '900',
                // height: '400',
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,  //视频时长时间显示
                    remainingTimeDisplay: false,  //剩余时间显示
                    fullscreenToggle: true // 全屏按钮
                },
                errorDisplay: false,  //错误显示
                posterImage: false,  //视频的预览海报图片显示
                bigPlayButton: true,  //视频中间的播放按钮显示
                textTrackDisplay: false
            }
        }
    },
    created() {
        this.getShipVideoList();
    },
    mounted() {
    },
    methods: {
        searchHandler() {
            this.pageNum = 1;
            this.getShipVideoList();
        },
        resetHandler() {
            this.searchForm = {
                shipName: '',
                shipId: '',
                searchDateArr: []
            }
            this.getShipVideoList();
        },
        videoDetailHandler(index) {
            let obj = this.videoDetailList[index]
            this.currentVideoDetailObj = obj;
            if (obj.isVideo) {
                setTimeout(() => {
                    this.initVideoPlayer();
                }, 500);
            }
        },
        initVideoPlayer() {
            // 视频初始化
            this.player = this.$video(this.$refs.videoPlayer, this.playerOptions);
            this.player.src(this.currentVideoDetailObj.url);
            this.player.play();
        },
        closeModalHandler() {
            this.videoDetailShow = false;
        },
        recordCellTapHandler(index) {
            let recordObj = this.videoList[index];
            customPrint('对象信息:' + JSON.stringify(recordObj));
            this.getShipVideoDetail(recordObj);
        },
        pageChange(num) {
            this.pageNum = num;
            this.getShipVideoList();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getShipVideoList();
        },
        getShipVideoList() {
            let param = {
                pageSize: this.pageSize,
                pageNum: this.pageNum,
                mmsiDataPageDTO: {}
            }
            if (this.searchForm.shipName) {
                param.mmsiDataPageDTO.shipName = this.searchForm.shipName;
            }
            if (this.searchForm.shipId) {
                param.mmsiDataPageDTO.shipId = this.searchForm.shipId;
            }
            if (this.searchForm.searchDateArr.length) {
                param.mmsiDataPageDTO.startTime = getDateByDatePicker(this.searchForm.searchDateArr[0]);
                param.mmsiDataPageDTO.endTime = getDateByDatePicker(this.searchForm.searchDateArr[1]);
            }
            getShopVideoInfoList(param).then(res => {
                customPrint('船舶视频列表:' + JSON.stringify(res));
                if (res.code == 0) {
                    this.total = res.data.total;
                    let list = res.data.list;
                    // list.forEach(item => {
                    //     item.createTime = timeFormatSeconds(item.createTime);
                    // })
                    this.videoList = list;
                } else { }
            })
        },
        getShipVideoDetail(obj) {
            let params = {
                mmsi: obj.mmsi,
                batchNo: obj.batchNo
            }
            getVideoDetailInfo(params).then(res => {
                // customPrint('视频图片详情:' + JSON.stringify(res));
                if (res.code == 0) {
                    let list = res.data;
                    this.videoDetailShow = true;
                    list.forEach(item => {
                        item.isVideo = false;
                        if (item.url.endsWith('.mp4')) {
                            item.isVideo = true;
                            item.smallUrl = list[0].smallUrl;
                        }
                    })
                    customPrint('数组回调:' + JSON.stringify(list));
                    this.videoDetailList = list;
                    this.currentVideoDetailObj = list[0];
                } else { }
            })
        }
    }
}
</script>

<style lang="scss">
.record-container {
    padding: 0 150px;
    height: 100%;
    overflow-y: auto;

    .search-section {
        display: flex;
        justify-content: flex-start;
        padding-bottom: 20px;

        .search-item {
            margin-left: 20px;

            &:first-child {
                margin-left: 0;
            }
        }

        .search-title {
            font-size: 16px;
            color: #fff;
        }
    }

    .header-section {
        padding: 10px 0;
        margin-top: 20px;

        .header-title {
            color: #f4f4f4;
            font-size: 16px;
        }
    }

    .tool-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .record-content {
        .record-list {
            display: flex;
            flex-wrap: wrap;
            margin-left: -10px;

            .record-item {
                width: calc((100% - 40px)/3);
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }

    .page-section {
        text-align: center;
    }

    .mask {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($color: #000000, $alpha: 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;

        .detail-content {
            background-color: #fff;
            border-radius: 10px;
            padding: 0 10px;

            .detail-header {
                padding: 20px 0;

                .detail-header-title {
                    font-size: 18px;
                }
            }

            .btn-section {
                padding: 20px 0;
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;
            }

            .video-section {
                width: 600px;
                height: 400px;
                background-color: #eee;

                .video-img {
                    width: 100%;
                    height: 100%;
                }

                .video-js {
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    background-color: transparent;

                    .vjs-tech {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .img-list {
                width: 600px;
                height: 150px;
                display: flex;
                padding-top: 10px;

                .img-list-item {
                    width: 300px;
                    height: 150px;
                    margin-left: 10px;
                    cursor: pointer;
                    position: relative;

                    .video-img {
                        width: 100%;
                        height: 100%;
                    }

                    &:first-child {
                        margin-left: 0;
                    }

                    .play-img {
                        width: 30px;
                        height: 30px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        margin-top: -15px;
                        margin-left: -15px;
                    }
                }
            }
        }
    }
}
</style>