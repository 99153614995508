<template>
    <div class="menu-address">
        <div :class="{ selected: index === indexPath }" :key="indexPath" v-for="(item, indexPath) in addressList"
            class="menu-item">
            <span class="item-title">{{ item.title }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        addressList: {
            type: Array,
            default() {
                return []
            }
        },
        index: {
            type: Number,
            default() {
                return 0
            }
        }
    }
}
</script>

<style lang="scss">
.menu-address {
    background-color: #fff;
    height: 30px;
    display: flex;

    .menu-item {
        padding: 0 10px;
        cursor: pointer;

        .item-title {
            line-height: 30px;
        }
    }

    .selected {
        background-color: #5d94cb;
        color: #fff;
    }
}
</style>