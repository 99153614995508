//获得年月日时分秒
//传入日期//例：2020-10-27T14:36:23
export function timeFormatSeconds(date) {
    return new Date(date)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
}

export function getDateByDatePicker(t) {
    if (t == undefined || t == '') {
        return t;
    }
    var date = new Date(t),
        Y = date.getFullYear() + "-",
        M =
            (date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1) + "-",
        D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()),
        h =
            (date.getHours()  < 10
                ? "0" + date.getHours()
                : date.getHours()) + ":",
        m =
            (date.getMinutes() < 10
                ? "0" + date.getMinutes()
                : date.getMinutes()) + ":",
        s =
            (date.getSeconds()  < 10
                ? "0" + date.getSeconds()
                : date.getSeconds());
    return Y + M + D + ' ' + h + m + s;
}

// 2022-09-19 20:31:20
export function subYearMonthDayTimeStr(date) {
    return date.substring(5, 19).replace('-', '/');
}

export function customPrint(str) {
    let printEnable = false;
    if (printEnable) {
        console.log(str);
    }
}

/**
 * 获取当前日期时间
 */
export function getCurrentDateTime() {
    var now = new Date();

    var year = now.getFullYear(); //年
    var month = now.getMonth() + 1; //月
    var day = now.getDate(); //日

    var hh = now.getHours(); //时
    var mm = now.getMinutes(); //分
    var ss = now.getSeconds(); //分

    var clock = year + "-";

    if (month < 10) clock += "0";

    clock += month + "-";

    if (day < 10) clock += "0";

    clock += day + " ";

    if (hh < 10) clock += "0";

    clock += hh + ":";
    if (mm < 10) clock += "0";
    clock += mm + ":";

    if (ss < 10) clock += "0";
    clock += ss;
    return clock;
}

// 防抖
export const antiShake = (fn, t) => {
    let delay = t || 2000;
    let timer;
    return function () {
        let args = arguments;
        if (timer) {
            clearTimeout(timer);
        }
        let callNow = !timer;
        timer = setTimeout(() => {
            timer = null;
        }, delay);
        if (callNow) fn.apply(this, args);
    };
};

// 节流
export const throttle = (fn, t = 2000) => {
    var timers = null;
    return function () {
        var context = this;
        var argst = arguments;
        if (!timers) {
            timers = setTimeout(function () {
                fn.apply(context, argst);
                timers = null;
            }, t);
        }
    };
};

//c：对象数组排序的键，
//d：排序方式，"positive"正序，"inverted"倒序。
export function sortTimeArr(key, type) {
    return function (a, b) {
        var value1 = a[key];
        var value2 = b[key];
        if (type == "positive") {//正序
            return new Date(value1) - new Date(value2);
        }
        else if (type == "inverted") {//倒序
            return new Date(value2) - new Date(value1);
        }
    }
}
// data.sort(aa('time', 'positive'));
// console.log(data);

