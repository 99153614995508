<template>
    <div @click="recordCellAction" class="record-cell">
        <div class="cell-left">
            <p class="code first">船舶名称：{{ recordObj.shipName ? recordObj.shipName : '暂无' }}</p>
            <p class="code">船舶编码：{{ recordObj.mmsi }}</p>
            <p class="time">{{ recordObj.createTime }}</p>
        </div>
        <div class="cell-right">
            <img class="cell-img" :src="recordObj.smallUrl" alt="">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        recordObj: {
            type: Object,
            default() {
                return {};
            }
        },
        index: {
            type: Number,
            default() {
                return 0;
            }
        }
    },
    methods: {
        recordCellAction() {
            this.$emit('recordCellTap', this.index);
        }
    },
}
</script>

<style lang="scss">
.record-cell {
    width: 100%;
    height: 140px;
    background-color: rgba($color: gray, $alpha: .3);
    margin-top: 10px;
    display: flex;
    cursor: pointer;

    .cell-left {
        width: 75%;
        color: #fff;
        padding-left: 20px;

        .code {
            font-size: 16px;
            font-weight: 500;
            margin-top: 10px;
        }

        .first {
            margin-top: 20px;
        }

        .time {
            font-size: 14px;
            margin-top: 5px;
            color: #a4a4a4;
        }
    }

    .cell-right {
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;


        .cell-img {
            width: 60px;
            height: 60px;
        }
    }
}
</style>